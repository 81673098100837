import { StyleSheet } from 'aphrodite';
import { FONTS } from '../../../styles/fonts';
import COLORS from '../../../styles/colors';
import QUERIES from '../../../styles/queries';

const styles = StyleSheet.create({
  root: {
    width: '100vw',
    height: '100vh',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 999,
    background: 'rgba(255,255,255,0.35)'
  },
  content: {
    width: '100%',
    maxWidth: 480,
    margin: '64px auto',
    background: 'white',
    borderRadius: 12,
    boxShadow: '0 16px 40px rgba(0,0,0,0.12)',
    padding: 32,
    [QUERIES.mobile]: {
      margin: '0 auto',
    }
  },
  title: {
    fontFamily: FONTS.Content,
    color: COLORS.offBlack,
    margin: 0,
    fontSize: 24,
    lineHeight: '32px'
  },
  input: {
    marginTop: 12,
    marginBottom: 12,
    width: '100%',
    padding: 16,
    fontWeight: 'bold',
    fontSize: 16,
    color: COLORS.offBlack,
    borderRadius: 12,
    border: '1px solid grey'
  },
  benefitLi: {
    display: 'flex',
    fontSize: 16,
    fontWeight: 'bold',
    flexDirection: 'row',
    alignItems: 'center',
    color: COLORS.jjdGreyish,
  },
  checkCircle: {
    color: COLORS.jjdRed,
    marginRight: 8
  },


  // Bernard:
  giveUsACallContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    maxWidth: 400,
    margin: '32px auto 0 auto'
  },
  phoneNumber: {
    fontSize: 32,
    textDecoration: 'none',
    color: COLORS.lightBlack,
    fontWeight: 'bold',
    [QUERIES.desktop]: {
      fontSize: 42
    }
  },
  bernardImg: {
    width: 60,
    height: 60,
    borderRadius: 60
  },
  bernardImgContainer: {
    width: 60,
    height: 60,
    position: 'relative'
  },
  onlineCircle: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: 18,
    height: 18,
    borderRadius: 18,
    border: '2px solid white',
    background: COLORS.kgGreen
  },
  spamBlurb: {
    fontSize: 14, paddingLeft: 24, color: COLORS.offBlack, fontStyle: 'italic',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  logoLinksUl: {
    'user-select': 'none',
    margin: 0,
    display: 'flex',
    height: 32,
    alignItems: 'center',
    paddingLeft: 0,
    marginBottom: 24
  },
  headerLogo: {
    height: "100%",
    width: 100,
    draggable: false,
    'user-select': 'none'
  },
  headerIconLogo: {
    height: 26,
    width: 26,
    marginRight: 12
  },
  revealBtn: {
    borderRadius: 48,
    fontFamily: 'Nunito',
    fontWeight: 600,

  }
});

export default styles;
