import React from "react";
import styles from "./PricesList.styles";
import { css } from "aphrodite";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { CUSTOM_VAN_CHOICES } from "../../../library/vans";
import PrimaryButton from "../../../components/buttons/PrimaryButton/PrimaryButton";
import Utils from "../../../library/utils";
import Shield from "@material-ui/icons/AccountBox";
import { KIFFGO } from "../../../library/kiffgo";
import TimerIcon from "@material-ui/icons/Commute";
import LiftingIcon from "@material-ui/icons/TimerRounded";
import Check from "@material-ui/icons/CheckRounded";
import ExcellentReviews from "../../../components/booking/ExcellentReviews/ExcellentReviews";
import GiveBernyACall from "../../../components/booking/GiveBernyACall/GiveBernyACall";
var moment = require("moment");

const PricesList = (props) => {
  const durationInMinutes = moment.duration(
    props.bookingDistance.duration,
    "minutes"
  );
  return (
    <div className={css(styles.root)}>
      <div className={css(styles.content)}>
        <div className={css(styles.vansContainer)}>
          {CUSTOM_VAN_CHOICES.map((choice, index) => {
            const selected = props.selectedVanIndex === index;

            let price = { estimate: 0 };
            price = props.bookingPricesCustomChoices.vanSizes[choice.vanSize][
              choice.possibleOptions[
                props.bookingPricesCustomChoices.vanSizes[choice.vanSize]
                  .selectedHelperIndex
              ].estimateFromReducer
            ]
              ? props.bookingPricesCustomChoices.vanSizes[choice.vanSize][
                  choice.possibleOptions[
                    props.bookingPricesCustomChoices.vanSizes[choice.vanSize]
                      .selectedHelperIndex
                  ].estimateFromReducer
                ]
              : { estimate: 0 };

            /* props.bookingPricesCustomChoices.vanSizes[choice.vanSize][
                choice.possibleOptions[
                  props.bookingPricesCustomChoices.vanSizes[choice.vanSize]
                    .selectedHelperIndex
                ].estimateFromReducer
              ]; */

            return (
              <div
                key={index}
                className={css(
                  styles.singleVan,
                  selected && styles.selectedVan
                )}
                onClick={() => props.chooseVanOption(index)}
              >
                <div className={css(styles.vanContent)}>
                  <div className={css(styles.vanHeader)}>
                    <span
                      className={css(
                        styles.vanLabel,
                        selected && styles.vanLabelSelected
                      )}
                    >
                      {choice.name}
                    </span>
                    {/* index === 1 && (
                    <span className={css(styles.savePill)}>Best value</span>
                  ) */}
                  </div>

                  <div className={css(styles.imgAndPriceRow)}>
                    <div className={css(styles.vanImgContainer)}>
                      <img
                        alt={choice.name}
                        className={css(styles.vanImg)}
                        src={choice.imgRealistic}
                      />
                    </div>
                    <div className={css(styles.pricesContainer)}>
                      <div className={css(styles.oldPrice)}>
                        <span className={css(styles.fromLabel)}>from</span>
                      </div>
                      <div className={css(styles.price)}>
                        {Utils.getPoundsAndPennies(price.estimate)}

                        {/* Utils.getPoundsAndPennies(
                        props.bookingPricesCustomChoices.vanSizes[
                          choice.vanSize
                        ][choice.estimateFromReducer]
                      ) */}
                      </div>
                      <div className={css(styles.oldPrice)}>
                        <span className={css(styles.hoursLabel)}>
                          <span style={{ opacity: 0.35 }}>( </span>min{" "}
                          {/* props.bookingDistance.minimumTimeHours}{" "}
                          {props.bookingDistance.minimumTimeHours === 1
                            ? "hour"
                            : "hours" */}
                          2 hours
                          <span style={{ opacity: 0.35 }}> )</span>
                        </span>
                      </div>
                    </div>
                  </div>

                  {selected && (
                    <>
                      <p className={css(styles.description)}>
                        {choice.description}
                      </p>
                      <ul className={css(styles.sellingPointsList)}>
                        <li className={css(styles.sellingPointItem)}>
                          <TimerIcon
                            className={css(styles.sellingPointCheck)}
                          />
                          Driving time:{" "}
                          {moment
                            .utc(durationInMinutes.asMilliseconds())
                            .format("H[h]mm[min]")}
                        </li>
                        <li className={css(styles.sellingPointItem)}>
                          <LiftingIcon
                            className={css(styles.sellingPointCheck)}
                          />
                          Loading time:{" "}
                          {props.bookingDistance.minimumTimeHours * 60 -
                            props.bookingDistance.duration}{" "}
                          minutes{" "}
                          <span style={{ opacity: 0.5 }}>
                            - add more in next step
                          </span>
                        </li>
                      </ul>
                      <h4 className={css(styles.heading4)}>
                        Choose for extra help
                      </h4>

                      <RadioGroup
                        aria-label="gender"
                        name="gender1"
                        value={
                          props.bookingPricesCustomChoices.vanSizes[
                            choice.vanSize
                          ].selectedHelperIndex
                        }
                        onChange={(e) => {
                          props.setSelectedHelperIndex(
                            choice.vanSize,
                            parseInt(e.target.value)
                          );
                        }}
                      >
                        {choice.possibleOptions.map((item, index) => {
                          return (
                            <FormControlLabel
                              value={index}
                              control={
                                <Radio
                                  className={css(styles.sellingPointItem)}
                                />
                              }
                              label={item.description}
                            />
                          );
                        })}
                      </RadioGroup>

                      <div className={css(styles.nextButtonContainer)}>
                        <PrimaryButton
                          className={css(styles.selectDealBtn)}
                          large={true}
                          style={{ width: "100%" }}
                          disabled={props.disabled}
                          onClick={props.goNext}
                          title={"Select deal"}
                          loading={props.loading}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            );
          })}

          <div className={css(styles.insuredContainer)}>
            <div style={{ flex: 1 }} />
            <Shield className={css(styles.shield)} />
            <span className={css(styles.insuredText)}>
              All drivers are insured and vetted by JJD.
            </span>
            <div style={{ flex: 1 }} />
          </div>

          <GiveBernyACall />
        </div>
      </div>
      <ExcellentReviews />
    </div>
  );
};

export default PricesList;
