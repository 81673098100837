import React, { Component } from 'react';
import MobileNumberPopup from './MobileNumberPopup';
import { connect } from 'react-redux';
import { setMobileNumber } from '../../../actions/UserDetailsActions';
import { saveLead } from '../../../actions/LeadActions';
import ReactGA from 'react-ga';

class MobileNumberPopupContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileNumber: '',
      loading: false
    }
  }


  componentDidMount() {
    var root = document.getElementsByTagName('html')[0]; // '0' to assign the first (and only `HTML` tag)
    root.setAttribute('class', 'modal-open');
  }

  onChangeMobileNumber = (e) => this.setState({mobileNumber: e.target.value});

  viewPrices = () => {
    this.props.sendMagicLead(this.state.mobileNumber);
    ReactGA.event({
      category: 'Web booking',
      action: 'Has provided number to view prices',
      label: 'Mobile Number Popup'
    });
    this.setState({loading: true});
    setTimeout(() => {
      this.props.setMobileNumber(this.state.mobileNumber)
    }, 600)
  };

  render() {
    return (
      <MobileNumberPopup
        {...this.props}
        onChangeMobileNumber={this.onChangeMobileNumber}
        mobileNumber={this.state.mobileNumber}
        viewPrices={this.viewPrices}
        loading={this.state.loading}
      />
    )
  }

  componentWillUnmount() {
    var root = document.getElementsByTagName('html')[0]; // '0' to assign the first (and only `HTML` tag)
    root.removeAttribute('class', 'modal-open');
    document.removeEventListener('mousedown', this.handleClick, false);
  }
}

const mapStateToProps = (props) => ({

});

const mapDispatchToProps = {
  setMobileNumber: setMobileNumber,
  sendMagicLead: saveLead
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileNumberPopupContainer);
