import React from 'react';
import styles from './MobileNumberPopup.styles';
import { css } from 'aphrodite';
import PrimaryButton from '../../../components/buttons/PrimaryButton/PrimaryButton';
import Utils from '../../../library/utils';
import CheckCircle from '@material-ui/icons/CheckCircle';
import COLORS from '../../../styles/colors.js'

const MobileNumberPopup = (props) => (
  <div className={css(styles.root)}>
    <div className={css(styles.content)}>

      <ul className={css(styles.logoLinksUl)}>
        <li>
          <img alt={'JJD man and van'} className={css(styles.headerLogo)} draggable={false}
            src={require('../../../assets/images/jjd-logo.png')} />
        </li>
      </ul>


      <h1 className={css(styles.title)}>
        Please enter your mobile number
      </h1>
      <p style={{ fontSize: 16, color: COLORS.jjdGreyish }}>Your mobile number is required for:</p>
      <ul>
        <li className={css(styles.benefitLi)}><CheckCircle className={css(styles.checkCircle)} /> Best price guarantee</li>
        <li className={css(styles.benefitLi)}><CheckCircle className={css(styles.checkCircle)} /> Show prices instantly</li>
        <li className={css(styles.benefitLi)}><CheckCircle className={css(styles.checkCircle)} /> Check you're not a robot</li>
      </ul>
      <input
        className={css(styles.input)}
        value={props.mobileNumber}
        onChange={props.onChangeMobileNumber}
        placeholder={'Mobile Number, e.g. 07700900100'}
      />
      <PrimaryButton
        disabled={!Utils.isValidMobile(props.mobileNumber)}
        onClick={props.viewPrices}
        title={'Reveal prices'}
        className={css(styles.revealBtn)}
        style={{ width: '100%' }}
        large={true}
        loading={props.loading}
      />

      {/* <div className={css(styles.giveUsACallContainer)}>
        <div className={css(styles.bernardImgContainer)}>
          <img alt={'Cheap man and van London'} className={css(styles.bernardImg)}
               src={'https://uc.chatra.io/a1fb1bff-c1f7-4c96-9d9b-5f7adb817c07/-/crop/712x712/152,0/-/preview/-/resize/200x200/'}/>
          <div className={css(styles.onlineCircle)}/>
        </div>
        <div className={css(styles.spamBlurb)}>
          "If you find a better price online, please call us so we can match it!" -
          Bernie
        </div>
      </div> */}


    </div>
  </div>
);

export default MobileNumberPopup;
