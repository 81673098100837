import React from 'react';
import VanSizeContainer from '../containers/bookingStep1/PricesList/PricesListContainer';
import Layout from '../containers/gatsby/layout';

const VanSizePage = (props) => (
  <Layout hideHeader={true} hideFooter={true} location={props.location}>
    <VanSizeContainer/>
  </Layout>
);

export default VanSizePage;
