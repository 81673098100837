import React, { Component } from "react";
import BookingSteppers from "../../../components/booking/BookingHeader/BookingHeader";
import connect from "react-redux/es/connect/connect";
import { setVanSize } from "./PricesListActions";
import { setSelectedHelperIndex } from "../../../actions/PriceActions";

import PricesList from "./PricesList";
import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";
import { navigate } from "gatsby";
import { window } from "browser-monads";
import { CUSTOM_VAN_CHOICES } from "../../../library/vans";
import { setAssistanceLevel } from "../../bookingStep2/Customise/CustomiseActions";
import MobileNumberPopupContainer from "../MobileNumberPopup/MobileNumberPopupContainer";
import { css } from "aphrodite";
import styles from "./PricesList.styles";
import Utils from "../../../library/utils";
import { saveLead } from "../../../actions/LeadActions";
import Api from "../../../api";

class PricesListContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      trackingEventsFired: false,
      loading: false,

      showMobileNumberPopup:
        !Utils.isValidMobile(this.props.userDetails.mobileNumber) &&
        typeof window !== "undefined" &&
        typeof window.performance !== "undefined" &&
        typeof performance !== "undefined" &&
        typeof performance.navigation !== "undefined" &&
        typeof performance.navigation.type === "number" &&
        performance.navigation.type === 1, // Automatically blur if reloaded
    };

    this.showAwfulPopupTimeout = null;
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.showAwfulPopupTimeout && clearTimeout(this.showAwfulPopupTimeout);
    if (
      !this.props.userDetails.mobileNumber &&
      !this.state.showMobileNumberPopup
    ) {
      this.showAwfulPopupTimeout = setTimeout(() => {
        this.setState({ showMobileNumberPopup: true });
      }, 500);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.userDetails.mobileNumber !== this.props.userDetails.mobileNumber
    )
      this.setState({ showMobileNumberPopup: false });
  }

  goNext = () => {

    ReactGA.event({
      category: "Web booking",
      action: "Has chosen van size",
      label: "Van Size",
    });
    ReactPixel.track("Lead");
    this.setState(
      {
        loading: true,
      },
      () => {
        setTimeout(() => {



          navigate("/your-inventory");
        }, 800);
      }
    );
  };

  chooseVanOption = (index) => {
    const vanSizeIndex = CUSTOM_VAN_CHOICES[index].vanSizeIndex;
    this.props.setVanSize(vanSizeIndex);
  };

  render() {
    return (
      <>
        {this.state.showMobileNumberPopup && <MobileNumberPopupContainer />}
        <div
          className={css(
            styles.wrapper,
            this.state.showMobileNumberPopup && styles.blurredWrapper
          )}
        >
          <BookingSteppers
            title={"Select a van size"}
            backUrl={"/book"}
            disabled={typeof this.props.selectedVanIndex !== "number"}
          />
          <PricesList
            chooseVanOption={this.chooseVanOption}
            loading={this.state.loading}
            {...this.props}
            // disabled={typeof this.state.selectedChoice !== "number"}
            goNext={this.goNext}
          />
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedVanIndex: state.bookingGeneral.vanSizeIndex,
    bookingPricesCustomChoices: state.bookingPricesCustomChoices,
    bookingDistance: state.bookingDistance,
    userDetails: state.userDetails,
    bookingAddresses: state.bookingAddresses
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setVanSize: (vanSizeIndex) => dispatch(setVanSize(vanSizeIndex)),
    setAssistanceLevel: (index) => dispatch(setAssistanceLevel(index)),
    setSelectedHelperIndex: (vanSize, selectedIndex) =>
      dispatch(setSelectedHelperIndex(vanSize, selectedIndex)),

  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PricesListContainer);
