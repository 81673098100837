import { StyleSheet } from "aphrodite";
import QUERIES from "../../../styles/queries";
import COLORS from "../../../styles/colors";

const styles = StyleSheet.create({
  root: {
    // ...
  },
  wrapper: {
    transition: "200ms",
  },
  blurredWrapper: {
    filter: "blur(8px)",
  },
  content: {
    maxWidth: 664,
    margin: "0 auto",
    width: "100%",
    [QUERIES.desktop]: {
      padding: 16,
      width: "calc(100% - 32px)",
    },
    minHeight: "100vh",
  },
  fromLabel: {
    color: COLORS.jjdGreyish,
    fontWeight: "normal",
    textTransform: "capitalize",
    fontSize: 14,
    marginRight: 8,
  },
  hoursLabel: {
    // fontWeight: 'bold',
    fontSize: 14,
    fontWeight: "normal",
    marginRight: 12,
    // fontFamily: FONTS.Headers
  },
  insuredContainer: {
    padding: 32,
    background: "white",
    boxShadow: "0 8px 40px rgba(0,0,0,0.08)",
    borderRadius: 12,
    marginBottom: 24,
    display: "flex",
    flexDirection: "row",
    width: "100%",
    textAlign: "center",
    flexWrap: "wrap",
  },
  shield: {
    color: COLORS.jjdRed,
    [QUERIES.mobile]: {
      flex: "0 0 100%",
    },
  },
  insuredText: {
    color: COLORS.jjdGreyish,
    fontWeight: "normal",
    paddingLeft: 8,
    [QUERIES.mobile]: {
      flex: "0 0 100%",
      padding: 32,
      paddingTop: 12,
      paddingBottom: 12,
    },
  },
  titleArea: {
    textAlign: "center",
    paddingTop: 12,
    paddingBottom: 32,
    [QUERIES.mobile]: {
      display: "none",
    },
  },
  vansContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    padding: 16,
  },
  singleVan: {
    flex: "0 0 100%",
    width: "100%",
    marginBottom: 32,
    background: COLORS.white,
    boxShadow: "0 8px 40px rgba(0,0,0,0.08)",
    borderRadius: 12,
    position: "relative",
    ":hover": {
      cursor: "pointer",
    },
    [QUERIES.desktop]: {
      ":hover": {
        // transform: 'translateX(-6px)'
      },
    },
    // opacity: 0.65,
    border: "4px solid white",
  },
  vanContent: {
    padding: 16,
  },
  selectedVan: {
    // background: COLORS.kgGreen,
    border: "4px solid #c50000",
    opacity: 1,
  },
  vanLabel: {
    color: COLORS.offBlack,
    padding: 4,
    paddingLeft: 16,
    paddingRight: 16,
    borderRadius: 32,
    fontSize: 20,
    marginRight: 12,
    fontWeight: "normal",
  },
  vanLabelDiscount: {
    background: COLORS.lightPaper,
    color: "#aeaeae",
    [QUERIES.mobile]: { display: "none" },
  },
  savePill: {
    textDecoration: "none",
    fontSize: 12,
    fontWeight: "bold",
    background: "#ffbc3e",
    color: COLORS.offBlack,
    padding: 4,
    paddingLeft: 16,
    paddingRight: 16,
    borderRadius: 32,
  },
  vanHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  vanLabelSelected: {
    background: COLORS.jjdRed,
    color: COLORS.white,
  },
  vanLabelSecondary: {
    background: COLORS.paper,
    color: COLORS.offBlack,
    padding: 4,
    paddingLeft: 16,
    paddingRight: 16,
    borderRadius: 32,
    fontSize: 12,
    marginLeft: 12,
  },
  imgAndPriceRow: {
    display: "flex",
    flexDirection: "row",
    padding: 16,
  },
  pricesContainer: {
    flex: 1,
    textAlign: "right",
  },
  discountText: {
    fontSize: 12,
    color: COLORS.hotPink,
    textAlign: "left",
  },
  oldPrice: {
    color: "#aeaeae",
    fontWeight: "bold",
    // textDecoration: 'line-through'
  },
  discountPill: {
    fontSize: 12,
    textDecoration: "none",
    fontWeight: "bold",
    background: COLORS.paper,
    color: COLORS.lightBlack,
    padding: 2,
    paddingLeft: 16,
    paddingRight: 16,
    borderRadius: 32,
    marginRight: 8,
  },
  price: {
    fontSize: 36,
    fontWeight: 500,
    color: COLORS.jjdRed,
    // paddingBottom: 4,
    lineHeight: "34px",
  },
  vanImgContainer: {
    flex: 1,
  },
  vanImg: {
    height: 75,
    [QUERIES.mobile]: {
      height: 50,
      marginTop: 10,
    },
  },
  vanDesc: {
    padding: 24,
    paddingTop: 6,
    marginBottom: 0,
    fontSize: 14,
    fontWeight: "bold",
  },
  selectedVanDesc: {
    color: COLORS.white,
  },
  nextButtonContainer: {
    width: "calc(100% - 32px)",
    paddingBottom: 16,
    margin: "0 auto",
    marginTop: 24,
  },
  sellingPointsList: {
    fontSize: 14,
    margin: 0,
    paddingBottom: 16,
  },
  sellingPointItem: {
    fontSize: 14,
    paddingBottom: 4,
    fontWeight: "normal",
  },
  description: {
    listStyle: "initial",
    margin: 0,
    fontSize: "20px",
    color: "#050505",
    paddingLeft: "40px",
    marginBottom: "20px",
  },
  descListItem: {
    fontSize: 14,
    paddingBottom: 4,
    fontWeight: "normal",
  },
  sellingPointLiftingRed: {
    color: COLORS.orangeText,
  },
  sellingPointLiftingGreen: {
    color: COLORS.jjdMaroon,
  },
  sellingPointCheck: {
    width: 14,
    height: 18,
    marginRight: 6,
    paddingTop: 6,
    // color: '#d6d6d6'
  },
  giveUsACallContainer: {
    textAlign: "center",
    width: "100%",
    marginTop: 64,
  },
  phoneNumber: {
    fontSize: 32,
    textDecoration: "none",
    color: COLORS.lightBlack,
    fontWeight: "bold",
    [QUERIES.desktop]: {
      fontSize: 42,
    },
  },
  bernardImg: {
    width: 60,
    height: 60,
    borderRadius: 60,
  },
  bernardImgContainer: {
    width: 60,
    height: 60,
    position: "relative",
    margin: "0 auto",
  },
  onlineCircle: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: 18,
    height: 18,
    borderRadius: 18,
    border: "2px solid white",
    background: COLORS.kgGreen,
  },
  loadingLabel: {
    fontSize: 12,
    paddingLeft: 32,
    color: "#7d7d7d",
  },
  liftingHelpChoicesContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    padding: 12,
    paddingBottom: 24,
    paddingTop: 0,
    flexWrap: "wrap",
  },
  liftingHelpChoice: {
    background: COLORS.lightPaper,
    color: "#aeaeae",
    fontWeight: "bold",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: 100,
    borderRadius: 4,
    textAlign: "center",
    margin: 8,
    flex: 1,
    fontSize: 12,
    boxShadow: "1px 4px 8px rgba(0,0,0,0.12)",
    // transform: 'scale(0.8)',
    [QUERIES.mobile]: {
      flex: "0 0 40%",
    },
  },
  liftingHelpChoiceSelected: {
    // transform: 'scale(1)',
    background: COLORS.kgGreen,
    color: COLORS.white,
    textShadow: "1px 1px 2px #079c7a",
  },
  choiceIcon: {
    width: 24,
    height: 24,
    marginBottom: 4,
  },
  selectDealBtn: {
    margin: "0 auto",
    borderRadius: 48,
    maxWidth: 280,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textTransform: "capitalize",
    fontFamily: "Nunito",
  },
  heading4: {
    color: COLORS.jjdRed,
    padding: "0px 44px",
    fontSize: "20px",
  },
});

export default styles;
